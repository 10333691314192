import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('common', ['storeConfigurationLoaded']),
    ...mapGetters('persisted', ['isBetaAccessCodeValidated', 'showEAIVPage']),
    isStoreActive() {
      // ToDo: SSR convert it to clientStoreConfigurationLoaded
      if (!this.storeConfigurationLoaded || !this.businessConfigurations)
        return false

      if (
        this.businessConfigurations.mode == 3 &&
        !this.isBetaAccessCodeValidated
      )
        return false
      if (
        this.businessConfigurations.mode == 2 &&
        !this.isBetaAccessCodeValidated
      )
        return false
      if (this.businessConfigurations.mode == 1) return false
      if (this.businessConfigurations.mode == 6) return false

      return true
    },
    canLoadDisclaimer() {
      if (!this.isStoreActive) return false

      return !!this.businessConfigurations?.showDisclaimer
    },
    isAgeVerificationRequired() {
      let isRequired = false

      if (
        this.businessConfigurations?.isStoreAgeRestricted &&
        (this.businessConfigurations?.enableAgeRestrictedBrowsing ||
          this.businessConfigurations?.enableAgeRestrictedCheckout) &&
        this.showEAIVPage === true
      )
        isRequired = true
      return isRequired
    },
    canLoadAnnouncement() {
      if (!this.isStoreActive) return false

      if (
        this.isAgeVerificationRequired &&
        !this.$route.meta?.skipAgeVerification
      )
        return false

      return !!this.businessConfigurations?.showAnnouncement
    },
  },
  mounted() {
    if (this.canLoadDisclaimer) this.$store.dispatch('common/getDisclaimer')
    else {
      let unwatchLoadDisclaimer = this.$watch('canLoadDisclaimer', (newVal) => {
        if (newVal) {
          this.$store.dispatch('common/getDisclaimer')

          unwatchLoadDisclaimer()
        }
      })
    }

    if (this.canLoadAnnouncement) this.$store.dispatch('common/getAnnouncement')
    else {
      let unwatchLoadAnnouncement = this.$watch(
        'canLoadAnnouncement',
        (newVal) => {
          if (newVal) {
            this.$store.dispatch('common/getAnnouncement')
            unwatchLoadAnnouncement()
          }
        }
      )
    }
  },
}
